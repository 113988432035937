






































































import {
  Component, Inject, Watch, Vue,
} from 'vue-property-decorator';

import Card from '@/components/material/Card.vue';
import { TableHeader } from '@/components/layout/models/table.d';
import { ServiceHook } from '@/models/service-hook-maintenance.d';
import ServiceHookService from '@/services/service-hook-service';
import { VDataTableOptions, SnackbarOptions } from '@/models/form';
import { getModule } from 'vuex-module-decorators';
import AppState from '@/store/modules/app-module';
import LocalState from '@/store/modules/local-state-module';
import EcSnackBar from 'common-components/src/components/form/ec-snack-bar.vue';
import axios, { CancelTokenSource } from 'axios';
import ServiceHookTest from '@/components/service-hook-maintenance/service-hook-test.vue';

const appState = getModule(AppState);
const localState = getModule(LocalState);

@Component({
  components: {
    Card,
    EcSnackBar,
    ServiceHookTest
  },
})
export default class ServiceHookListView extends Vue {
  @Inject() ServiceHookService!: ServiceHookService;

  headers: TableHeader[] = [];

  items: ServiceHook[] = [];

  totalItems = 0;

  loading = false;

  page = 1;

  size = localState.defaultPageSize;

  sort = ['description,asc'];

  serviceHook: ServiceHook = {} as ServiceHook;

  snackbarOptions: SnackbarOptions = EcSnackBar.makeDefaultOptions();

  cancellationToken!: CancelTokenSource;

  showTestDialog = false;

  get numberOfPages() {
    if (this.size === 0) return 1;
    return Math.max(1, Math.ceil(this.totalItems / this.size));
  }

  async mounted(): Promise<void> {
    this.serviceHook = {} as ServiceHook;
    this.headers = [
      {
        sortable: true,
        sortDirection: 'asc',
        text: 'Name',
        value: 'name',
      },
      {
        sortable: false,
        sortDirection: '',
        text: 'Endpoint',
        value: 'options.uri',
      },
      {
        sortable: false,
        sortDirection: '',
        text: 'Description',
        value: 'description',
      },
      {
        sortable: false,
        sortDirection: '',
        text: 'Delete',
        value: 'deleteAction',
      },
    ];
  }

  get apiFault() {
    return appState.apiFault;
  }

  @Watch('$route', { immediate: true })
  onRouteChange() {
    const { page = 1, size = this.size, sort = this.sort } = this.$route.query || {};

    this.page = +page;
    this.size = +size;
    this.sort = [sort].flat();

    this.loadPage();
  }

  optionsUpdated(options: VDataTableOptions) {
    const {
      sortBy, sortDesc, page, itemsPerPage,
    } = options;

    const sort = sortBy.map((by, index) => `${by},${sortDesc[index] ? 'desc' : 'asc'}`);

    const currentQuery = this.$route.query;
    const newQuery = {
      page: `${page}`,
      size: `${itemsPerPage}`,
      sort,
    };
    if (sort.length === 0) {
      delete newQuery.sort;
    }

    if (JSON.stringify(currentQuery) === JSON.stringify(newQuery)) return;

    this.$router.push({
      query: newQuery,
    });
  }

  async loadPage() {
    this.loading = true;

    const pagination = {
      page: this.page,
      size: this.size,
      sort: this.sort,
    };

    try {
      if (this.cancellationToken)
         this.cancellationToken.cancel('Reloaded list');
      this.cancellationToken = axios.CancelToken.source();

      const list = await this.ServiceHookService.listServiceHooks(
        pagination,
        this.cancellationToken.token
      );

      this.size = list.page.size;
      this.page = list.page.number;
      this.totalItems = list.page.totalElements;
      this.items = list._embedded['service-hooks'];

      localState.setDefaultPageSize(this.size);
    } catch (error) {
      if (!axios.isCancel(error)) {
        this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions(error);
      }
    } finally {
      this.loading = false;
    }
  }

  async deleteItem(serviceHook: ServiceHook) {
    try {
      const text = 'If you delete this service hook, screening results will no longer be sent to the endpoint.';
      appState.openDialog({
        title: 'Delete Service Hook?',
        text: `<p>Are you sure?</p><p>${text}</p>`,
        actions: [{
          name: 'Delete',
          color: 'warning',
          handler: async () => {
            this.snackbarOptions = EcSnackBar.makeProgressOptions('Deleting service hook ...');
            this.ServiceHookService.deleteServiceHook(serviceHook.id)
              .then(() => {
                this.snackbarOptions.value = false;
                this.$nextTick(() => { this.snackbarOptions = EcSnackBar.makeSuccessfulOptions('Successfully deleted service hook'); });
                this.loadPage();
              })
            .catch((error: Error) => {
              this.snackbarOptions.value = false;
              this.$nextTick(() => { this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions(`Failed to delete service hook: ${error.message}`); });
            }
          )
        },
      },
      {
        name: 'Cancel',
        color: 'primary',
        handler: () => Promise.resolve(false),
      }],
    });
  } catch (error) {
      this.snackbarOptions.value = false;
      this.$nextTick(() => { this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions(`Failed to delete service hook : ${error}`); });
    }
  }
}
